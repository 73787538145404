.container {
  position: relative;
}

#resume {
  position: relative;
}

#resume[data-theme="dark"] {
  background: #14161a;

  .resume-main-title span{
    color: #914263;
  }
  .resume-main-title h2{
    color: #e2c7c7;
  }

  .blob-green,
  .blob-pink {
    display: none;
  }
  .resume-item h5 {
    background: #1f2228;
  }

  .resume-title, .resume-item h4{
    color: #999584;
  }

  .resume-item a{
    color: #e2c7c7;
  }

  .resume-item a:hover{
    color: #eee6e6;
  }
}

.resume-main-title span {
  color: #e2c7c7;
}

.blob-green {
  position: absolute;
  top: -12rem;
  right: -10rem;
  height: 30rem;
  rotate: 10deg;
}

.blob-pink {
  position: absolute;
  bottom: 0;
  left: -15rem;
  height: 30rem;
}

.resume .resume-title {
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #14161a;
}

.resume .resume-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid #699a9f;
  position: relative;
}

.resume .resume-item h4 {
  line-height: 18px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #14161a;

  margin-bottom: 10px;
}

.resume .resume-item h5 {
  font-size: 16px;
  background: #eef0f2;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #699a9f;
}


@media screen and (max-width: 1400px){
  .blob-green,
  .blob-pink {
    display: none;
  }  
}