:root {
  --steps: 21;
}


.blob-left {
  z-index: -1;
  position: absolute;
  top: 0;
  height: 20rem;
}

#hero {
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.hero-image {
  width: 40vw;
  float: right;
}

.socials {
  margin: 2rem 0;
}

.socials a:hover{
  color: #699a9f;
}

.button {
  padding: 14px 16px;
  border-radius: 5px;
  margin: 0 2rem 0 0;
  border: none;
  font-weight: bold;
}

.cv-button {
    background: linear-gradient(
      45deg,
      #e2c7c7 0%,
      #914263 100%
    );
    color: #fffefe;
  
    background-size: 200% 200%;
    transition: background-position 0.3s ease;
}

.hire-button {
    background: linear-gradient(
      45deg,
      #699a9f 0%,
      #0e3034 100%
    );
    color: #fffefe;

    background-size: 200% 200%;
    transition: background-position 0.3s ease;
}

.button:hover {
  background-position: right bottom; 
  color:#fffefe;
}

.img-fluid {
  max-width: 100%;
  min-width: 300px;
  height: auto;
  max-height: 500px;
}

.green{
  color: #699a9f;
}

.typing {
  /* font-family: "Inconsolata", Consolas, monospace; */
  position: relative;
  &::after {
    content: "|";
    position: absolute;
    right: 0;
    width: 100%;
    background: #fff;
    animation: typing 3s steps(var(--steps)) forwards, caret 1s infinite;
  }
}



[data-theme="dark"] .typing {
  color: #699a9f;
  position: relative;
  &::after {
    color: #699a9f;
    background: var(--background-dark);
  }
}


[data-theme="dark"] h1, [data-theme="dark"] .social-icon{
  color: var(--color-light);
}


@keyframes typing {
  to {
    width: 0;
  }
}
@keyframes caret {
  50% {
    color: transparent;
  }
}

.social-icon {
  margin-right: 2rem;
  color: var(--background-dark)
}


@media screen and (max-width: 756px) {
  .blob-left{
    display: none;
  }
  #hero{
    padding-top: 65px !important;
  }
}


.hero-row .btn{
  padding: 14px 16px !important;
  font-weight: bolder;
  font-size: inherit;
  border: none;
}


blockquote {
  border-left: 5px solid #0e3034;
  padding: 1.5rem 3rem;
  position: relative;
  }
/* 
  blockquote:before {
  content: "\201C";
  font-family: serif;
  position: absolute;
  left: 20px;
  top: 20px;
  color: #fffefe;
  background: #0e3034;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  font-size: 3rem;
  line-height: 1.35;
  text-align: center;
  } */
  blockquote p {
  font-size: x-large;
  }
  blockquote cite {
  font-style: normal;
  }