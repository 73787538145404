#about {
  background-color: #f0efeb;
  padding: 3rem 0 0 0;

  /* background-image: url('../assets/images/about-bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
     */
}

#about[data-theme="dark"] {
  background: #14161a;
  .about-title span {
    color: #914263;
  }
  .about-title h2 {
    color: #e2c7c7;
  }

  .list-group-item a{
    color: #e2c7c7
  }

  .list-group-item a:hover{
    color: #eee6e6;
  }
}
.particle-container {
  width: 800px;
  height: 500px;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  margin-top: 0.5rem !important;

}

canvas {
  border: 3px solid   #020202;
  background-color: #14161a;
  border-radius: 24px;

}

#tsparticles {
  width: 100%;
  height: 70%;
}

/* .about-content {
  width: 100vw;
  background: rgba( 240, 239, 235, 0.8 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
  -webkit-backdrop-filter: blur( 15px );
  border-radius: 10px;
  margin: auto;
} */

.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media (max-width: 575px) {
  .section-title h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .section-title span {
    font-size: 38px;
  }
}

.about-image {
  max-height: 1076px;
  padding: 3rem;
  width: auto;
  float: left;
  margin-top: auto;
}

.about ul {
  list-style: none;
  padding: 0;
}

.about ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.about ul strong {
  margin: 0 10px;
}

.about p:last-child {
  margin-bottom: 0;
}


.education svg {
  margin: auto 0;
}

.education .label, .education span {
  margin-bottom: auto;
}


.main-row {
  align-items: flex-start;
}

.about-list li {
  flex-wrap: wrap;
}

.list-group-item{
  background: transparent !important;
  border: none !important;
  color: inherit !important;
}

.list-group-item svg{
  margin-right: 0.5rem;
}

.about-desc{
  /* background-color: #0e3034;
  color: #fff; */
  text-align: center;
  max-width: 800px;
  /* text-align: justify; */
  padding: 0 3rem !important;
  /* margin-bottom: 2rem; */
}