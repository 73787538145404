[data-theme="dark"] {
    background-color: #14161a !important;

  .error {
    color: #e2c7c7;
  }

  .oops {
    color: #914263;
  }

  .message {
    /* color: #cf85a4; */
    color: #e2c7c7
  }

  .home-btn {
    background-color: #914263;
    color: #f0efeb;
  }

  .home-btn:hover {
    background-color: #ad6d88;
  }

  .not-found-socials a {
    color: #e2c7c7;
  }

  .not-found-socials a:hover {
    color: #ad6d88;
  }
}

#not-found {
  background-color: #f0efeb;
  margin: 0;

}

.not-found-container{
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.error {
  font-size: 8rem;
  color: #699a9f;
}

.oops {
  font-size: 3rem;
  color: #14161a;
}

.message {
  font-size: 1.25rem;
  margin-bottom: 20px;
  color: #0e3034;
}

.home-btn {
  background-color: #0e3034;
  color: #fff;
  font-size: 1.25rem;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.home-btn:hover {
  background-color: #14161a;
  color: #fff;
}

.not-found-socials{
    display: flex;
    justify-content: space-evenly;  
    max-width: 400px;
}
.not-found-socials a {
  display: inline-block;
  margin-right: 10px;
  font-size: 24px;
  color: #537b7f;
  text-decoration: none;
}

.not-found-socials a:last-child {
  margin-right: 0;
}

.not-found-socials a:hover{
    color: #0e3034;
}

.not-found-socials a{
  max-width: 100%;
  height: auto;
}
