#portfolio {
  background-color: #E2C7C7;
  position: relative;
}

#portfolio[data-theme="dark"] {
  background: #14161a;

  /* .section-title span {
    color: #0e3034;
  }
  .section-title h2,
  h1 {
    color: #699a9f;
  } */

  .overlay,
  .details {
    background-color: rgba(20, 22, 26, 0.9);
    color: #f0efeb;
    box-shadow: none;
  }

  .details .close h1,
  .details h1 {
    color: #f0efeb !important;
  }

  .details h1,
  .details h2 {
    color: #f0efeb !important;
  }

  .details .technology {
    background-color: rgb(240, 239, 235, 0.5);
  }
}

.gallery {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
}

.gallery-image {
  margin: 0 12px 12px 12px;
  cursor: pointer;
  position: relative;
  max-width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  filter: grayscale(70%);
}

.gallery-image:hover{
  filter: grayscale(0%);
}

.gallery-image img,
.image-overlay {
  border-radius: 0.75rem;
}

.image-text {
  position: absolute;
  color: #fffefe;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: center;
  align-items: center;
}

.image-text .technology {
  background-color: rgb(240, 239, 235, 0.5);
  margin: 7px 7px 7px 0;
  width: fit-content;
  border-radius: 5px;
}

.image-text .row {
  display: flex;
  justify-content: space-evenly;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 22, 26, 0.7);
  opacity: 0;
  transition: opacity 0.3s;
}

.gallery-image:hover .image-overlay,
.gallery-image:hover .image-text {
  opacity: 1;
}

.details {
  position: fixed;
  top: 0;
  right: 0;
  color: #14161a;
  height: 100vh;
  width: 50vw;
  background: rgba(240, 239, 235, 0.9);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(17.5px);
  z-index: 100;
  padding: 3rem;
  padding-top: 4rem;
  overflow: auto;
  box-shadow: 0 0 04em #14161a;
  overflow-wrap: anywhere;
}

.details .close h1,
.details h1 {
  cursor: pointer;
}

.details h1,
.details h2 {
  padding: 7px 0;
}

.details img {
  border-radius: 10px;
  margin: 2rem 0;
}

.details .technology {
  padding: 7px 12px;
  margin: 7px;
  background-color: rgba(124, 122, 118, 0.5);
  border-radius: 10px;
  width: fit-content;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(240, 239, 235, 0.9);
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.overlay {
  opacity: 1;
  pointer-events: auto;
}

.no-scroll {
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .details {
    position: fixed;
    top: 0;
    color: #14161a;
    height: 100vh;
    width: 100vw;
  }
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}



.nav-tabs {
  justify-content: center;
  margin-bottom: 3rem !important;
  border-bottom: 2px solid #f0efeb !important;
  padding: 3px;
  max-width: 90%;
  align-items: center;
  margin: auto;
}

.nav-item {
  margin: 0 1.5rem;
}

.nav-link {
  color: #333 !important;
  border-radius: 4px !important;
  border: none !important;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.nav-link:hover {
  background-color: rgba(240, 239, 235, 0.5);
  color: #000 !important;
}

.nav-tabs .nav-link.active {
  background-color: #f0efeb !important;
  color: #000 !important;
  cursor: default;
}
