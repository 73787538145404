footer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
}

[data-theme="dark"]{
    background: #14161a;
    color: #F0EFEB;
}